<template>
  <div class="container-fluid annotator">
    <div
      class="container-fluid ner nertypes d-flex justify-content-around"
      :class="{ topFixed: scrollTop }"
    >
      <div class="row">
        <div class="col-md-12 types">
          <span
            v-for="(types, key) in __types"
            :key="key"
            class="annotators"
            @mouseout="$removeTooltip()"
            @mouseover="$getTooltip(types[0], types[1], $event)"
            @click="$typeSelect(types[0])"
            :class="types[0]"
            >{{ types[0] }}</span
          >
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <h1>Corretor de anotações</h1>
      <h2 class="time">{{ counterMinutes }}:{{ counterSeconds }}</h2>
      <input class="input_id" type="text" v-model="paragraph_id" placeholder="Buscar pelo ID do parágrafo" @change="getAnnotationsfromdbid(paragraph_id)">
      <div class="annotator__box">
        <transition-group name="fade">
          <template v-if="annotations.length > 0">
            <div
              class="annotator__paragraphs"
              v-for="(an, key) in __annotations"
              :key="key"
            >
              <template v-if="!an['finished']">
                <div
                  class="annotator__paragraphs__header not-finished--header"
                  @click="openRevisor(an)"
                >
                  <div
                    class="paragraphs__header--marker"
                    :class="{
                      finished: an.finished,
                      'not-finished': !an.finished,
                    }"
                  ></div>
                  <div class="paragraphs__header--info">
                    MATRÍCULA ID: {{ an.matricula_id }}
                  </div>
                  <div class="paragraphs__header--info">
                    PARAGRAPH ID: {{ an.paragraph_id }}
                  </div>
                </div>

                <div
                  class="annotator__paragraphs__infos shadow paragraph"
                  v-if="an['newannotation']"
                >
                  <div
                    class="annotator__infos--text marker"
                    v-if="an['newannotation']"
                  >
                    <h3>Corrigir anotação</h3>

                    <div
                      :id="`txt-${an['newannotation']['id']}`"
                      @mouseup="getSelection(key)"
                    ></div>

                    <div class="row buttons">
                      <div class="col-md-3" @click="literalSelectionToggle()">
                        <button>Literal</button>
                      </div>
                      <div class="col-md-3" @click="cleanAnnotation(key)">
                        <button>Limpar Anotação</button>
                      </div>
                      <div class="col-md-3" @click="restartParagraph(key)">
                        <button>Resetar</button>
                      </div>
                      <div class="col-md-3" @click="send(key)">
                        <button>Enviar</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="annotator__paragraphs__infos shadow">
                  <template>
                    <div
                      class="annotator__infos--text"
                      v-for="(us, key_) in an.user"
                      :key="key_"
                    >
                      <div class="annotator__infos--annotator">
                        Anotador: {{ us.name }}<br />
                      </div>

                      <div v-html="us.paragraph"></div>
                      <button @click="chooseTocorrection(key, us, key_)">
                        Escolher para correção
                      </button>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { RevannotatorMixin } from "./Revannotator.mixin.js";
import LoaderComponent from "../../components/ui/loader/loader";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {};
  },
  watch: {},
  props: {},
  components: {},
  name: "Revannotator",
  mixins: [RevannotatorMixin],
};
</script>
<style src="./Revannotator.less" lang="less" />
