import {
    getAnnotationsnotfinished,
    updateAnnotation,
    getAnnotationsbyid
} from "../../services/annotator.services";

import { mapGetters } from "vuex";

import * as jquery from 'jquery'
const $ = jquery

export const RevannotatorMixin = {
    data() {
        return {
            annotations: [],
            paragraph_id: '',
            nospans: false,
            typeSelected: '',
            nerModel: {
                spans: [],
                paragraphText: ''
            },
            literalSelector: false,
            scrollTop: false,
            totalTime: 0,
            tempoOcioso: 0,
            counterSeconds: '',
            counterMinutes: '',
        };
    },
    created() {},
    mounted() {
        this.getAnnotationsfromdb();
        this.timeCounter(1000);

    },
    filters: {},
    watch: {
        windowsize() {
            let scrolled = this.$windowY.scrollY
            if (scrolled > 50) {

                this.scrollTop = true
            } else {
                this.scrollTop = false
            }
        },
        mousemove() {
            return this.$mousemove.timer
        },
        environment() {
            return process.env.NODE_ENV
        }

    },
    computed: {
        windowsize() {

            return this.$windowY.scrollY
        },
        __annotations() {
            return this.annotations;
        },
        __types() {
            return this.$types()
        },
        selectedType() {
            return this.$typeselected['typeselected']
        }

    },
    methods: {
        ...mapGetters([
            "getUsername",
        ]),

        async getAnnotationsfromdb() {
            this.loading = true
            try {
                this.totalTime = 0
                this.counterSeconds = 0
                this.counterMinutes = 0
                this.loading = false
                this.annotations = []
                const { data } = await getAnnotationsnotfinished();
                this.annotations = this.$duplicate(data.data.not_finished_paragraphs)
                this.mountSpans()
            } catch (e) {
                this.$tokentest(e.response.status)
                this.$toast.error('Ocorreu um erro ao carregar o parágrafo')
                this.loading = false
                console.log(e)
            }

        },

        async getAnnotationsfromdbid(paragraph_id) {
            this.loading = true
            try {
                this.totalTime = 0
                this.counterSeconds = 0
                this.counterMinutes = 0
                this.loading = false
                this.annotations = []
                const { data } = await getAnnotationsbyid({ 'paragraph_id': paragraph_id });
                this.annotations = this.$duplicate(data.data.not_finished_paragraphs)
                this.mountSpans()
            } catch (e) {
                this.$tokentest(e.response.status)
                this.$toast.error('Parágrafo não existente na base de dados ou já finalizado')
                this.loading = false
                console.log(e)
            }
        },
        timeCounter(time = 1000) {
            return setInterval(() => {
                if (this.mousemove > 60000) {
                    this.totalTime = this.totalTime + 0

                } else {
                    ++this.totalTime
                }
                this.counterSeconds = this.$pad(this.totalTime % 60);
                this.counterMinutes = this.$pad(parseInt(this.totalTime / 60));
            }, time);
        },

        openRevisor(annotation) {
            this.$store.dispatch('setReviewparagraph', annotation)
            window.open('reviewannotator', '_blank')
        },
        mountSpans() {
            this.$nextTick(() => {
                let id = 0
                for (let j in this.annotations) {
                    this.annotations[j].user = this.annotations[j].user.map(val => {
                        val.text = this.annotations[j].text
                        let paragraph = val.text.split('')
                        for (let i in val.data) {
                            for (let it = 0; it <= paragraph.length; it++) {
                                if (val.data[i].start === it) {
                                    id = id + 1
                                    val.data[i]['id'] = id
                                    paragraph[it] = `<span class="${val.data[i].label}" style="${val.data[i]['color']}" start="${val.data[i].start}" end="${val.data[i].end}" id="${id}">${paragraph[it]}`
                                } else if (val.data[i].end === it) {
                                    if (paragraph[it] != undefined) {
                                        paragraph[it] = `</span>${paragraph[it]}`
                                    }

                                }


                            }
                        }

                        val.paragraph = paragraph.join('')
                        return val
                    })
                }
            })
        },




        chooseTocorrection(key, data, user_index) {
            data['id'] = Math.random().toString()
            this.annotations[key]['newannotation'] = this.$duplicate(data)
            this.annotations[key]['newannotation']['spans'] = this.$duplicate(data.data.sort((a, b) => { return a.start - b.start }))
            this.annotations[key]['newannotation']['spansSend'] = this.$duplicate(data.data.sort((a, b) => { return a.start - b.start }))
            this.annotations[key]['newannotation']['counter'] = 0
            this.annotations[key]['newannotation']['user_index'] = user_index
            this.annotations[key]['newannotation']['nospan'] = false
            this.annotations[key]['newannotation'] = this.$nerMount('ner_double', this.annotations[key]['newannotation']).annotation
            this.$forceUpdate()

        },

        async getSelection(key) {

            try {
                this.annotations[key]['newannotation'] = await this.$getSelection(this.annotations[key]['newannotation'], this.selectedType, this.literalSelector)
            } catch (e) {
                this.$tokentest(e.response.status)
                this.$toast.error('Ocorreu um erro ao selecionar')
            }

        },


        toggleVisible(key) {
            this.annotations[key].visible = !this.annotations[key].visible;
            for (let i in this.annotations) {
                if (i != key) {
                    this.annotations.visible = false;
                }
            }
        },

        literalSelectionToggle() {
            if (this.literalSelector === true) {
                this.$toast.info('Selecionador literal desativado')
                this.literalSelector = false
            } else {
                this.$toast.info('Selecionador literal ativado')
                this.literalSelector = true
            }
        },

        async cleanAnnotation(key) {
            try {
                this.annotations[key]['newannotation'] = await this.$cleanAnnotation(this.annotations[key]['newannotation'])
            } catch (e) {
                this.$tokentest(e.response.status)
                this.$toast.error('Ocorreu um erro ao limpar a anotação')
            }

        },

        async restartParagraph(key) {
            try {
                this.annotations[key]['newannotation'] = await this.$restartParagraph(this.annotations[key]['newannotation'])
            } catch (e) {
                this.$tokentest(e.response.status)
                this.$toast.error('Ocorreu um erro ao resetar o parágrafo')
            }

        },


        async send(key) {
            if (this.annotations[key]['newannotation']['spans'].length === 1 && this.annotations[key]['newannotation']['spans'][0].type === "NON") {
                return this.$toast.error('Você ainda não anotou nenhuma entidade')
            }
            let confirm = window.confirm('Clique aqui para confirmar a correção da anotação')
            if (confirm) {
                try {
                    for (const i in this.annotations[key]['newannotation']['spans']) {
                        this.annotations[key]['newannotation']['spans'][i]['type'] = this.annotations[key]['newannotation']['spans'][i]['label']
                        delete this.annotations[key]['newannotation']['spans'][i]['id']
                        delete this.annotations[key]['newannotation']['spans'][i]['color']
                    }
                    let sendannotation = {
                        paragraph_id: this.annotations[key]['paragraph_id'],
                        spans: this.annotations[key]['newannotation']['spans'],
                        user_index: this.annotations[key]['newannotation']['user_index']
                    }
                    await updateAnnotation(sendannotation)
                    this.annotations[key]['finished'] = true
                    let cloudwatch = {
                        totaltime: this.totalTime,
                        matriculaid: this.annotations[key]['matricula_id'],
                        paragraph_id: this.annotations[key]['paragraph_id'],
                        minutes: this.counterMinutes,
                        seconds: this.counterSeconds,
                        username: this.getUsername(),
                        template: this.annotations[key]['template'],
                        modelspanuse: false,
                        initialspans: this.annotations[key]['newannotation']['data'].length,
                        finalspans: this.annotations[key]['newannotation']['spans'].length,
                        modelspans: 0
                    }
                    this.$savelog('admin_annotators', 'admin_annotators_data', JSON.stringify(cloudwatch))
                    this.getAnnotationsfromdb()
                    this.$toast.success('Anotação corrigida com sucesso')

                } catch (e) {
                    this.$tokentest(e.response.status)
                    this.$toast.error('Ocorreu um erro ao corrigir a anotação')
                    console.log(e)
                }
            } else {
                return
            }

        }


    },
};